import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'partner' ]
  static values = { url: String }

  bank_accounts() {
    if(this.partner_value == ''){
      document.getElementById('partner-bank-accounts').innerHTML = ''
    } else {
      fetch(this.urlValue + this.partner_value)
        .then(response => response.text())
        .then(html => document.getElementById('partner-bank-accounts').innerHTML = html)
    }
  }
  get partner_value() {
    return this.partnerTarget.value
  }
}

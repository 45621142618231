// NOTE: this is JS custom code for Partner and Project
document.addEventListener('turbolinks:load', () => {
  let cbox = document.getElementsByClassName('is_foreign');
  if(cbox.length > 0){
    let view = cbox[0].id.split('_')[0]
    cbox = document.getElementById(view + '_is_foreign')
    let select = document.getElementById(view + '_country');
    let option = document.createElement('option');
    option.value = 'ES';
    option.text = 'España';
    function check_foreign(){
      if(cbox.checked === true){
        if(select.value === 'ES'){
          select.value = 'FR';
        }
      } else {
        if(select.value != 'ES'){
          select.value = 'ES';
        }
      }
    }
    cbox.onclick = check_foreign;
    select.onchange = check_foreign;
    check_foreign();
  }
});

